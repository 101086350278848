<template>
  <v-container fluid>
    <SubTitleBar :title="'แก้ไขข้อมูลลูกค้า'" :subtitle="''" :goback="'tenant'">
    </SubTitleBar>
    <v-row class="pl-5 pr-10">
        <v-col cols="12">
            <v-card>
                <v-card-title class="font-weight-bold">
                    ข้อมูลทั่วไป
                </v-card-title>
                <v-card-text>
                    <TextField dense title='รหัสลูกค้า' v-model="model.customerId" :required="true"/>
                    <TextField dense title='ชื่อลูกค้า' v-model="model.tenantName" :required="true"/>
                    <TextField dense title='เลขประจำตัวผู้เสียภาษี'/>
                    <TextArea dense title='ที่อยู่' v-model="model.address"/>
                    <div>ผู้ติดต่อ</div>
                    <v-divider class="pt-1 pb-3"/>
                    <div v-for="(item,index) in model.contactPerson" :key="index">
                    <TextField dense title='ชื่อ นามสกุล' v-model="item.name" />
                    <TextField dense title='เบอร์ติดต่อ' v-model="item.mobile"/>
                    <TextField dense title='อีเมล์' v-model="item.email"/>
                    <v-divider class="pt-1 pb-2"/>
                    </div>
                </v-card-text>
            </v-card>
        </v-col>
         <v-col cols="12">
            <v-card>
                <v-card-title class="font-weight-bold">
                    ข้อมูลแพ็กเกจ
                </v-card-title>
                <v-card-text>
                    <v-divider/>
                    <v-row dense class="pt-3">
                        <v-col cols="6">
                         <Select title="แพ็กเกจ" placeholder="เลือกแพ็กเกจ" :required="true" />
                        </v-col>
                        <v-col cols="4">
                        <label class="text-title-4 natural--text text--darken-4"> รูปแบบแพ็กเกจ <span class="error--text">*</span>
                   <v-radio-group
      v-model="packageType"
      row
      :rules="packageTypeRules"
    >
      <v-radio
        label="Paid tier"
        value="paid"
      ></v-radio>
      <v-spacer/>
      <v-radio
        label="Free tier"
        value="free"
      ></v-radio>
       <v-spacer/>
    </v-radio-group>
                 </label>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
            <v-col cols="12">
            <v-card>
                <v-card-title class="font-weight-bold">
                    แอดมิน
                </v-card-title>
                <v-card-text>
                    <v-row dense>
                      <v-col cols="6">
   <TextField dense title='อีเมล์' v-model="email"/>
</v-col>
         <v-col cols="3">
   <Select dense title='Role' placeholder="Tenant Admin"/>
</v-col>
  <v-col cols="2" align-self="center">
    <v-btn large block color="primary" @click="inviteTenantAdmin(email)">เชิญสมาชิก</v-btn>
  </v-col>
                    </v-row>
                      <v-divider/>
                    <v-row dense class="pt-3" v-for="(item,index) in model.tenantAdmin" :key="index">
<v-col cols="6">
   <TextField dense title='อีเมล์' v-model="item.email"/>
</v-col>
         <v-col cols="3">
   <Select dense title='Role' placeholder="Tenant Admin"/>
</v-col>
   <v-col cols="2">
   <label class="text-title-4 natural--text text--darken-4"> Action <span class="error--text">*</span>
   <v-row class="align-center">
       <v-spacer/>
    <v-btn icon><v-icon>mdi-email-send-outline</v-icon></v-btn>
      <v-spacer/>
    <v-btn icon><v-icon>mdi-delete</v-icon></v-btn>
      <v-spacer/>
    <v-switch inset  v-model="item.active"/>
   </v-row>
   </label>
</v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
         <v-col cols="12">
            <v-card>
                <v-card-title class="font-weight-bold">
                    ประวัติแพ็กเกจ
                </v-card-title>
                <v-card-text>
                    <v-divider/>
                     <v-row dense class="pt-3" v-for="(item, index) in model.history" :key="index">
<v-col cols="6">
      <TextField dense title='แพ็กเกจ' v-model="item.subscriptionPackage"/>
</v-col>
<v-col cols="3">
  <DatePicker dense title='เริ่มต้น' v-model="item.startDate"/>
</v-col>
<v-col cols="3">
     <DatePicker dense title='สิ้นสุด' v-model="item.endDate"/>
</v-col>
</v-row>
                </v-card-text>
            </v-card>
        </v-col>
         <v-col cols="12">
            <v-card>
                <v-card-title class="font-weight-bold">
                    สถานะระบบปัจจุบัน
                </v-card-title>
                <v-card-text>
                     <v-divider class="pt-3"/>
                     จำนวนผู้คัดกรองในระบบ xxx คน
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
     <v-row class="pt-2 pl-5 pr-8 ma-2">
         <v-spacer/>
         <v-btn v-if="$auth.role.isSystemAdmin"  x-large width="250" height="54" color="primary" @click="onSave">บันทึก</v-btn>
     </v-row>
       <v-overlay :value="loading">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-dialog v-model="confirmDialog" persistent width="375">
        <ConfirmCard @onClose="confirmDialog = false" :title="'คุณต้องการยืนยันที่จะบันทึกข้อมูลหรือไม่'" :action="'บันทึก'" @onConfirm="patchTenant()"/>
        </v-dialog>
  </v-container>
</template>

<script>
import SubTitleBar from '@/components/common/SubTitleBar'
import TextField from '@/components/common/TextField'
import TextArea from '@/components/common/TextArea'
import Select from '@/components/common/Select'
import DatePicker from '@/components/common/DatePicker'
import ConfirmCard from '@/components/common/ConfirmCard'
import { getTenant, patchTenant, postTenantAdmin } from '@/api/'

export default {
  components: {
    SubTitleBar,
    TextField,
    TextArea,
    Select,
    DatePicker,
    ConfirmCard
  },
  data () {
    return {
      customerId: null,
      loading: false,
      model: {},
      packageType: null,
      packageRules: [v => !!v || 'กรุณาเลือกแพ็กเกจ'],
      packageTypeRules: [v => !!v || 'กรุณาเลือกรูปแบบแพ็กเกจ'],
      nameRules: [
        v => !!v || 'กรุณาระบุชื่อลูกค้า'
      ],
      idRules: [
        v => !!v || 'กรุณาระบุรหัสลูกค้า'
      ],
      confirmDialog: false,
      email: null
    }
  },
  methods: {
    onSave () {
      this.confirmDialog = true
    },
    patchTenant () {
      this.loading = true
      patchTenant({ id: this.model.id, etag: this.model._etag }, this.model, message => {
        if (message.data.code === 1) {
          this.model = message.data.result
          this.confirmDialog = false
        }
        this.loading = false
      }, error => {
        console.log(error)
        this.loading = false
      })
    },
    inviteTenantAdmin (value) {
      if (value) {
        postTenantAdmin({ tenantId: this.model.tenantId, email: value }, message => {
          if (message.data.code === 1) {
            this.fetchData()
          }
        }, error => {
          console.log(error)
        })
      }
    },
    fetchData () {
      this.loading = true
      getTenant({
        tenantId: this.$route.params.tenantId,
        tenantName: ''
      }, message => {
        if (message.data.code === 1) {
          if (message.data.result) {
            // console.log(message.data.result.items[0])
            this.model = message.data.result.items[0]
          } else {
            this.model = {}
          }
        }
        this.loading = false
      }, error => {
        this.loading = false
        console.log(error)
      })
    }
  },
  created () {
    this.$watch(
      () => this.$route.params,
      () => {
        this.fetchData()
      },
      // fetch the data when the view is created and the data is
      // already being observed
      { immediate: true }
    )
  }
}
</script>

<style>

</style>
